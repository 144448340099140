import React, { useState, useEffect, MouseEvent, ChangeEvent } from 'react'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import * as Masks from 'inter-frontend-lib-util-form/lib/masks'
import * as Validations from 'inter-frontend-lib-util-form/lib/validations'
import * as URLS from 'src/config/api/Urls'
import RangeInput from '../../../RangeInput/Form'
import AcceptTerms from 'src/components/AcceptTerms/ContactForm'

import intervalInterBlackJSON from '../../../RangeInput/assets/intervalInterBlack.json'
import PercentageInterBlackJSON from '../../../RangeInput/assets/percentageInterBlack.json'

import OrangeDsIcon from '../../MarkdownIcon/OrangeDsIcon'
import ErrorImg from './assets/images/erro.png'
import Close from 'inter-frontend-svgs/lib/v2/navigation/close'
import QrCodeInterBlackNaoCorrentista from './assets/images/qrcode-nao-correntista.png'
import QrCodeInterBlackCorrentista from './assets/images/qrcode-correntista.png'

import Success from './assets/images/success.png'

import { Container, CloseButton, Card, RadioBox, RadioButton } from './style'
import { sendCDPFormData, getParameterByName } from 'src/shared/helpers'

type InterBlackContactFormProps = {
  closeModal: () => void;
}
interface IFormValues {
  nome: string;
  sobrenome: string;
  email: string;
  celular: string;
  cpf: string;
  datanascimento: string;
  cliente?: string;
  range: string;
}

const valueMask = {
    0: 0,
    50000: 250000,
    100000: 300000,
    150000: 350000,
    200000: 400000,
    250000: 450000,
    300000: 500000,
    350000: 550000,
    400000: 600000,
    450000: 650000,
    500000: 700000,
    550000: 750000,
    600000: 800000,
    650000: 850000,
    700000: 900000,
    750000: 950000,
    800000: 100000,
}

function InterBlackContactForm ({ closeModal }: InterBlackContactFormProps) {
  const { register, errors, handleSubmit, setValue }: Pick<ReturnType<typeof useForm>, 'register' | 'errors' | 'handleSubmit' | 'setValue'> = useForm()
  const [ loading, setLoading ] = useState(false)
  const [ sent, setSent ] = useState(false)
  const [ error, setError ] = useState(false)
  const [ accept, setAccept ] = useState(false)
  const [ isClient, setIsClient ] = useState(true)
  const [ rangeValue, setRangeValue ] = useState<number>(0)
  const [ utmSource, setUtmSource ] = useState<string | string[] | null | undefined>(null)
  const [ utmMedium, setUtmMedium ] = useState<string | string[] | null | undefined>(null)
  const [ utmCampaign, setUtmCampaign ] = useState<string | string[] | null | undefined>(null)
  const [ utmContent, setUtmContent ] = useState<string | string[] | null | undefined>(null)
  const [ radioActive, setRadioActive ] = useState(false)

  useEffect(() => {
    setUtmSource(getParameterByName('utm_source', ''))
    setUtmMedium(getParameterByName('utm_medium', ''))
    setUtmCampaign(getParameterByName('utm_campaign', ''))
    setUtmContent(getParameterByName('utm_content', ''))
  }, [])

  const sendForm = async (data: IFormValues) => {
    setLoading(true)
    delete data.cliente

    const formData = {
      ...data,
      investe_corretora: radioActive ? true : false,
      cpf: data.cpf.replace(/\D/g, ''),
      celular: data.celular.replace(/\D/g, ''),
      datanascimento: data.datanascimento.replace(/\D/g, ',').split(',').reverse().join('-'),
      termoaceito: accept,
      investir: valueMask[parseInt(data.range)],
      utmCampaign: utmCampaign?.toString(),
      utmMedium: utmMedium?.toString(),
      utmSource: utmSource?.toString(),
      utmContent: utmContent?.toString(),
    }

    if (formData.range) { delete formData.range }

    try {
      await axios.post(`${URLS.CONTACT_FORM_POST_V5}/lead-lp-black`, [ formData ])
      setSent(true)
      sendCDPFormData({ formName: 'Formulario Inter Black', cpf: formData.cpf, email: formData.email })
    } catch (e) {
      setError(true)
      setLoading(false)
    }
  }

  const changeRange = (rangeValueInput: string) => {
    setRangeValue(parseInt(rangeValueInput))
  }

  const handleReturn = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setError(false)
    setSent(false)
    setLoading(false)
  }

  const closeButton = closeModal && (
    <CloseButton className='border-0 bg-transparent pt-3' onClick={closeModal}>
      <Close width='24' height='24' color='orange--extra' />
    </CloseButton>
  )

  return (
    <>
      { error && (
        <Container isModal={closeModal} className='d-flex align-items-center error'>
          {closeButton}
          <div className='col-12 text-center'>
            <h3 className='fs-20 lh-24 mb-5'>Ah não! Ocorreu um erro com a sua solicitação...</h3>
            <img src={ErrorImg} alt='Imagem de erro' />
            <p className='text-grayscale--400 fs-18 lh-22 pt-3'>
              Verifique a sua conexão com a internet e tente novamente. <br />
              <button
                onClick={handleReturn}
                title='Clique aqui para voltar'
                className='bg-transparent border-0 fw-600'
              >
                Clique aqui
              </button>
              para voltar.
            </p>
          </div>
        </Container>
      )}

      { sent && (
        <Container isModal={closeModal} className='d-flex align-items-center sent'>
          {closeButton}
          <div className='col-12 text-center px-md-0'>
            {isClient ? (
              <>
                <div className='col-12 mx-auto d-md-none text-center'>
                  <img src={Success} alt='Sucesso' width={64} height={64} />
                </div>

                <p className='fs-16 lh-20 text-grayscale--400 fw-600 text-left mt-4'>
                  Recebemos os seus dados. Entraremos em contato em breve.
                </p>

                <h3 className='fs-20 lh-25 text-grayscale--500 mb-4 fw-600 text-left'>
                  Você já pode
                  <a
                    href='https://bancointer.go.link/investimentos?adj_t=1c86a0oh&adj_adgroup=cr&adj_creative=formulario'
                    target='_blank'
                    rel='noreferrer'
                    className='text-orange--extra'
                  >
                    {' '}acessar o app
                  </a>
                  {' '}e fazer seus investimentos pra se tornar Inter Black.
                </h3>

                <div className='col-12 pb-2 mx-auto d-none d-md-block'>
                  <img src={QrCodeInterBlackCorrentista} alt='Baixe o App Inter' />
                </div>

                <div className='col-12 d-none d-md-block px-0 text-md-left'>
                  <hr />
                  <p className='fs-14 lh-17'>
                    Abra a câmera do seu celular e aponte para o QR Code acima. Você irá direto pra área de investimentos do app. É rápido e fácil!
                  </p>
                </div>
              </>
            ) : (
              <>
                <div className='col-12 mx-auto d-md-none text-center'>
                  <img src={Success} alt='Sucesso' width={64} height={64} />
                </div>

                <p className='fs-16 lh-20 text-grayscale--400 fw-600 text-left mt-4'>
                  Recebemos os seus dados.
                </p>

                <h3 className='fs-20 lh-25 text-grayscale--500 mb-4 fw-600 text-left'>
                  Agora
                  <a
                    href='https://bancointer.go.link/?adj_t=16gn8ijn&adj_adgroup=cr&adj_creative=lpblack'
                    target='_blank'
                    rel='noreferrer'
                    className='text-orange--extra'
                  >
                    {' '}baixe nosso app
                  </a>
                  {' '}pra se tornar Inter Black e aproveitar os benefícios.
                </h3>

                <div className='col-12 pb-2 mx-auto d-none d-md-block'>
                  <img src={QrCodeInterBlackNaoCorrentista} alt='Baixe o App Inter' />
                </div>

                <div className='col-12 d-none d-md-block px-0 text-md-left'>
                  <hr />
                  <p className='fs-14 lh-17'>
                    Abra a câmera do seu celular e aponte para o QR Code acima. É só baixar o app do Inter e clicar em ”Abrir conta completa e gratuita”. É rápido e fácil!
                  </p>
                </div>
              </>
            )}
          </div>
        </Container>
      )}

      <Container
        isModal={closeModal}
        className={`${error || sent ? 'd-none' : 'd-flex'} align-items-md-center align-items-lg-start`}
      >
        {closeButton}
        <form name='open_account' onSubmit={handleSubmit(sendForm)}>
          <div className='form--default'>
            <div className='row'>
              <div className='col-12 pt-3 pb-4 mb-2'>
                <h3 className='fs-20 lh-25 fs-md-32 lh-md-40 text-grayscale--500 mb-0'>
                  Preencha seus dados para dar os primeiros passos rumo ao Inter Black
                </h3>
              </div>
              <div className={`col-12 col-md-6 pb-3 d-flex flex-column ${errors.nome && 'error'}`}>
                <label htmlFor='nome' className='fs-14 lh-18 fw-600'>Nome</label>
                <input
                  ref={register({
                    required: 'Nome obrigatório',
                  })}
                  name='nome'
                  id='nome'
                  type='text'
                  maxLength={100}
                  placeholder='Digite seu nome'
                />
                {errors.nome && <p className='fs-12 lh-15 text-red--base mb-0 pt-1 text-right'>{errors.nome.message}</p>}
              </div>
              <div className={`col-12 col-md-6 pb-3 d-flex flex-column ${errors.sobrenome && 'error'}`}>
                <label htmlFor='sobrenome' className='fs-14 lh-18 fw-600'>Sobrenome</label>
                <input
                  ref={register({
                    required: 'Sobrenome obrigatório',
                  })}
                  name='sobrenome'
                  id='sobrenome'
                  type='text'
                  maxLength={100}
                  placeholder='Digite seu sobrenome'
                />
                {errors.sobrenome && <p className='fs-12 lh-15 text-red--base mb-0 pt-1 text-right'>{errors.sobrenome.message}</p>}
              </div>
              <div className={`col-12 pb-3 d-flex flex-column ${errors.email && 'error'}`}>
                <label htmlFor='email' className='fs-14 lh-18 fw-600'>E-mail</label>
                <input
                  ref={register({
                    required: 'E-mail obrigatório',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: 'Insira um e-mail válido',
                    },
                  })}
                  name='email'
                  id='email'
                  type='text'
                  placeholder='Digite seu e-mail'
                />
                {errors.email && <p className='fs-12 lh-15 text-red--base mb-0 pt-1 text-right'>{errors.email.message}</p>}
              </div>
              <div className={`col-12 col-md-6 pb-3 d-flex flex-column ${errors.celular && 'error'}`}>
                <label htmlFor='celular' className='fs-14 lh-18 fw-600'>Celular</label>
                <input
                  ref={register({
                    required: 'Telefone obrigatório',
                    pattern: {
                      value: /^\(\d{2}\) \d{4,5}-\d{4}$/,
                      message: 'Insira um número de celular válido',
                    },
                  })}
                  name='celular'
                  id='celular'
                  type='tel'
                  placeholder='Digite seu telefone'
                  maxLength={15}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('celular', Masks.MaskPHONE(event.currentTarget.value))}
                />
                {errors.celular && <p className='fs-12 lh-15 text-red--base mb-0 pt-1 text-right'>{errors.celular.message}</p>}
              </div>
              <div className={`col-12 col-md-6 pb-3 d-flex flex-column pb-1 ${errors.datanascimento && 'error'}`}>
                <label htmlFor='datanascimento' className='fs-14 lh-18 fw-600'>Data de Nascimento</label>
                <input
                  ref={register({
                    required: 'Digite uma data válida',
                    validate: {
                      isDate: (value: string) => Validations.dateValid(value) || 'Insira uma data de nascimento válida',
                    },
                  })}
                  name='datanascimento'
                  id='datanascimento'
                  type='tel'
                  placeholder='dd/mm/aaaa'
                  maxLength={10}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('datanascimento', Masks.MaskDATE(event.currentTarget.value))}
                />
                {errors.datanascimento && <p className='fs-12 lh-15 text-red--base mb-0 pt-1 text-red--base text-right'>{errors.datanascimento.message}</p>}
              </div>
              <div className={`col-12 col-md-6 pb-3 d-flex flex-column ${errors.cpf && 'error'}`}>
                <label htmlFor='cpf' className='fs-14 lh-18 fw-600'>CPF</label>
                <input
                  ref={register({
                    required: 'CPF obrigatório',
                    validate: {
                      isCpf: (value: string) => Validations.cpf(value) || 'CPF Inválido',
                    },
                  })}
                  name='cpf'
                  id='cpf'
                  type='tel'
                  placeholder='Insira um CPF válido'
                  onChange={(event: ChangeEvent<HTMLInputElement>) => setValue('cpf', Masks.MaskCPF(event.currentTarget.value))}
                />
                {errors.cpf && <p className='fs-12 lh-15 text-red--base mb-0 pt-1 text-right'>{errors.cpf.message}</p>}
              </div>
              <div className={`col-12 col-md-6 d-flex flex-column ${errors.cliente && 'error'}`}>
                <label htmlFor='estado' className='fs-14 lh-18 fw-600'>Já tem conta no Inter?</label>
                <div className='d-flex justify-content-start pt-1'>
                  <label className='radio-contato' htmlFor='cliente'>
                    <input
                      ref={register({
                        required: 'Informação obrigatória',
                      })}
                      type='radio'
                      id='cliente'
                      name='cliente'
                      value='sim'
                      onClick={() => setIsClient(true)}
                    />
                    <span className='radio-check' />
                    Já tenho
                  </label>
                  <label className='radio-contato' htmlFor='naoCliente'>
                    <input
                      ref={register({
                        required: 'Informação obrigatória',
                      })}
                      type='radio'
                      id='naoCliente'
                      name='cliente'
                      value='nao'
                      onClick={() => setIsClient(false)}
                    />
                    <span className='radio-check' />
                    Ainda não
                  </label>
                </div>
                {errors.cliente && <p className='fs-12 mt-1 mb-0 pt-1 text-red--base text-right'>{errors.cliente.message}</p>}
              </div>
              <div className={`col-12 pt-2 d-flex flex-column mb-2 ${errors.corretora_atual && 'error'}`}>
                <RadioBox active={radioActive && !errors.corretora_atual}>
                  <div className='d-flex align-center justify-content-between'>
                    <p className='fs-14 fw-700 text-grayscale--400 mb-0'>Já investe por outra corretora?</p>
                    <RadioButton type='button' onClick={() => setRadioActive(!radioActive)}>
                      <OrangeDsIcon size='MD' color={radioActive ? '#ff7a00' : '#B6B7BB'} icon={radioActive ? 'radio-on' : 'radio-off'} />
                    </RadioButton>
                  </div>
                  {
                    radioActive && (
                      <div className='d-flex flex-column mt-4'>
                        <label htmlFor='corretora' className='fs-14 lh-18 fw-400'>Por qual corretora você investe?</label>
                        <input
                          ref={register({
                            required: 'Nome obrigatório',
                          })}
                          name='corretora_atual'
                          id='corretora'
                          type='text'
                          className='corretora'
                          maxLength={200}
                          placeholder='Insira o nome da corretora'
                        />
                      </div>
                    )
                  }
                </RadioBox>
              </div>
              <div className={`col-12 pt-2 d-flex flex-column ${errors.range && 'error'}`}>
                <span className='fs-14 lh-18 mb-1 fw-600 text-grascale--400'>Quanto pretende investir?</span>
                <div className='col px-0'>
                  <RangeInput
                    minValue={0}
                    maxValue={800000}
                    onChange={changeRange}
                    interval={50000}
                    percentageRange={PercentageInterBlackJSON}
                    register={register({
                      required: 'Marque o quanto quer investir',
                      validate: {
                        min: (value: number) => value >= 50000,
                      },
                    })}
                  />
                </div>
                <div className='d-flex justify-content-between align-items-center flex-column'>
                  <p className='fs-12 lh-15 fw-300 mb-1'>
                    {intervalInterBlackJSON[rangeValue]}
                  </p>
                </div>
                {errors.range && <p className='fs-12 mt-1 mb-3 text-erro'>Marque o quanto quer investir</p>}
              </div>
              <div className='col-12 mb-4'>
                <Card>
                  <div className='mr-3 d-flex'>
                    <OrangeDsIcon icon='attention' color='#FFC700' size='MD' />
                  </div>
                  <div>
                    <span className='fs-12 lh-15 fw-600 d-block text-grayscale--500 mb-2'>Valor a ser investido</span>
                    <p className='fs-12 lh-15 fw-400 d-block text-grayscale--500 mb-0'>
                      Para ser Inter Black, é necessário que invista valores entre R$ 250.000,00 e R$ 1.000.000,00 em
                      qualquer produto da nossa plataforma.
                    </p>
                  </div>
                </Card>
              </div>
              <div className='col-12 pb-3 d-flex flex-column'>
                <AcceptTerms accept={accept} setAccept={setAccept} name='conta-digital-pf-black' product='Black' />
              </div>
              <div className='col-12 text-center mb-4 mb-md-0'>
                <button
                  type='submit' title='Enviar contato' disabled={!accept || loading}
                  className='btn btn-orange--extra fs-14 fw-600 rounded-2 mx-auto text-white text-none mb-4 mb-xl-0'
                >
                  {loading ? 'Enviando...' : 'Enviar'}
                </button>
              </div>
            </div>
          </div>
        </form>
      </Container>
    </>
  )
}

export default InterBlackContactForm
